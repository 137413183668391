import { Dialog } from '@/components/molecules/Dialog';
import { FC, useEffect, useState } from 'react';
import { PaymentCalculator } from '../PaymentCalculator/PaymentCalculator';

export interface PaymentCalculatorModalProps {
  open: boolean;
  close: () => void;
  initialMsrp: number;
  msrp: number;
  handleUpdateMSRP: (msrp: number) => void;
}

export const PaymentCalculatorModal: FC<PaymentCalculatorModalProps> = (
  props
) => {
  const { open, close } = props;
  const [msrp, setMsrp] = useState(props.initialMsrp);

  useEffect(() => {
    if (!open && props.initialMsrp !== msrp) {
      setMsrp(props.initialMsrp);
    }
  }, [open, props.initialMsrp, msrp]);

  const handleClose = () => {
    props.handleUpdateMSRP(Math.round(msrp));
    close();
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <PaymentCalculator
        type={'vlp'}
        expanded={false}
        salePrice={msrp}
        setSalePrice={(value) => setMsrp(value)}
        handleSearch={handleClose}
      />
    </Dialog>
  );
};
