import Button from '@/components/atoms/legacy/Button';
import TextButton from '@/components/atoms/legacy/TextButton';
import Accordion from '@/components/molecules/Accordion';
import Badge, { BadgeVariants } from '@/components/molecules/Badge';
import { DropdownMenuItemVariants } from '@/components/molecules/DropdownMenuItem';
import FormSelect from '@/components/molecules/FormSelect';
import { useZipContext } from '@/context/ZipContext';
import { DEFAULT_US_ZIP_CODE, SORT_BY_OPTIONS } from '@/lib/constants';
import { FilterChip, FilterType } from '@/types/filters';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { MapPinIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FetchProductsResult } from '../../../lib/schema/inventory/types';
import { SearchFilterGeneralProps } from '../SearchFilterGeneralInfoSection/SearchFilterGeneralInfoSection';

export interface SearchFilterChipsSectionProps
  extends SearchFilterGeneralProps {
  handleUpdateMake: (makes: string[]) => void;
  makesToModels: Record<string, string[]>;
  clearFilters: () => void;
  onSortChange: (sort: string) => void;
  sort: string;
  data?: FetchProductsResult | null;
  setFilterChipsList: (filterChips: FilterChip[]) => void;
  setIsClearingSearchInput: (isClearingSearchInput: boolean) => void;
  setZipCode: (zip: string) => void;
}

export const SearchFilterChipsSection: React.FC<
  SearchFilterChipsSectionProps
> = ({
  filters,
  handleUpdateMake = () => {},
  updateFilter,
  makesToModels = {},
  clearFilters,
  onSortChange = () => {},
  sort = '',
  data,
  setFilterChipsList,
  setIsClearingSearchInput,
  setZipCode,
}) => {
  const { userZip, userAddress } = useZipContext();
  const [openFilterChips, setOpenFilterChips] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<boolean>(false);
  const filterChipsRef = useRef<HTMLDivElement>(null);
  const isSearchConflicted = data?.size === 0 && !!filters.vector_search;

  const handleUpdateFilter = useCallback(
    (filterName: keyof FilterType, filterItem: string) => {
      const filterList = filters[filterName];
      if (filterName === 'vector_search') {
        setIsClearingSearchInput(true);
        updateFilter({
          fields: {
            [filterName]: '',
          },
        });
        return;
      }
      if (filterName === 'postal') {
        setZipCode('');
      }
      let updatedFilterList;
      if (Array.isArray(filterList)) {
        updatedFilterList = filterList.filter((item) => item !== filterItem);
      } else if (typeof filterList === 'string') {
        updatedFilterList = filterList
          .split(',')
          .filter((item) => item !== filterItem)
          .join(',');
      } else {
        updatedFilterList = filterList;
      }
      updateFilter({
        fields: {
          [filterName]: updatedFilterList,
        },
      });
    },
    [filters, updateFilter, setIsClearingSearchInput, setZipCode]
  );

  const selectedSortOption = useMemo(() => {
    const selectedSort = SORT_BY_OPTIONS.find(
      (option) => option.value === sort
    );
    return selectedSort?.text || 'Best Match';
  }, [sort]);

  const createFilterChips = useCallback(
    (filterList: string[], filterName: keyof FilterType) => {
      const filterValue = filterName;
      return filterList.map((filterItem) => ({
        filterName: filterValue,
        label: filterItem,
        onClose: () => {
          if (filterChipsRef.current) {
            filterChipsRef.current.style.height = 'fit-content';
          }
          if (filterName === 'make') {
            handleUpdateMake(filterList.filter((item) => item !== filterItem));
          } else {
            handleUpdateFilter(filterName, filterItem);
          }
        },
      }));
    },
    [handleUpdateFilter, handleUpdateMake]
  );

  const filterChips: FilterChip[] = useMemo(() => {
    const modelList = Object.values(makesToModels).flat();
    const makeChips: FilterChip[] = createFilterChips(filters.make, 'make');
    const modelChips: FilterChip[] = createFilterChips(
      filters.model.filter((modelName) => modelList.includes(modelName)),
      'model'
    );
    const bodyChips: FilterChip[] = createFilterChips(filters.body, 'body');
    const featureChips: FilterChip[] = createFilterChips(
      filters.features,
      'features'
    );
    const conditionChips: FilterChip[] = createFilterChips(
      filters.condition ? filters.condition.split(',') : [],
      'condition'
    );
    const fuelTypeChips: FilterChip[] = createFilterChips(
      filters.fuelType ? filters.fuelType.split(',') : [],
      'fuelType'
    );
    const vectorSearchChips: FilterChip[] = createFilterChips(
      filters.vector_search ? [filters.vector_search] : [],
      'vector_search'
    );

    const defaultZip =
      userAddress?.country === 'US' ? userZip : DEFAULT_US_ZIP_CODE;
    const locationChips: FilterChip[] = createFilterChips(
      filters.postal && filters.postal !== defaultZip ? [filters.postal] : [],
      'postal'
    );

    return [
      ...locationChips,
      ...makeChips,
      ...modelChips,
      ...bodyChips,
      ...featureChips,
      ...conditionChips,
      ...fuelTypeChips,
      ...vectorSearchChips,
    ];
  }, [makesToModels, filters, createFilterChips, userZip, userAddress]);

  useEffect(() => {
    if (filterChipsRef.current) {
      filterChipsRef.current.style.height = openFilterChips
        ? `${filterChipsRef.current.scrollHeight}px`
        : '0px';
    }
    setFilterChipsList(filterChips);
  }, [filterChips, openFilterChips, setFilterChipsList]);

  useEffect(() => {
    if (filterChips.length > 1) {
      setOpenFilterChips(true);
    }
  }, [filterChips]);

  return (
    <section className="ml:pt-xl">
      <section className="mb-[10px] hidden justify-between ml:mb-s ml:flex">
        <div className="flex items-center gap-s">
          <h4 className="text-h3SemiBold m:text-subHeadline3">Filters</h4>
          {filterChips && filterChips.length > 0 && !openFilterChips && (
            <div className="flex h-xl w-xl items-center justify-center rounded-full bg-blue-light p-xs text-badgeAlert text-blue-medium">
              {filterChips.length}
            </div>
          )}
        </div>
        <div className="flex items-center gap-s">
          <TextButton
            className="px-s text-body2Regular text-blue-medium"
            onClick={() => {
              clearFilters();
              setOpenFilterChips(false);
            }}
          >
            Reset all
          </TextButton>
          {filterChips?.length > 0 && (
            <div className="h-full border-[1px] border-solid border-neutral-200"></div>
          )}
          {filterChips && filterChips.length > 0 && (
            <Button
              className="border-none p-0"
              onClick={() => setOpenFilterChips(!openFilterChips)}
            >
              {openFilterChips ? (
                <ChevronUpIcon width={24} height={24} />
              ) : (
                <ChevronDownIcon width={24} height={24} />
              )}
            </Button>
          )}
        </div>
      </section>
      <section className="hidden flex-col-reverse gap-l ml:flex ml:flex-col">
        {filterChips?.length > 0 && (
          <div
            className="ease flex flex-wrap gap-s overflow-hidden transition-all duration-300"
            ref={filterChipsRef}
          >
            {filterChips
              .filter(
                (_, index) =>
                  !isSearchConflicted || index !== filterChips.length - 1
              )
              .map((chip, index) => {
                return (
                  <Badge
                    key={`${chip.label}_${index}`}
                    onClick={chip.onClose}
                    variant={
                      chip.filterName === 'vector_search'
                        ? BadgeVariants.Gray
                        : BadgeVariants.Blue
                    }
                    label={chip.label}
                    iconPosition="right"
                    secondaryIcon={
                      chip.filterName === 'postal' ? (
                        <MapPinIcon className="h-l w-l" />
                      ) : undefined
                    }
                    icon={
                      <XMarkIcon width={20} height={20} strokeWidth={1.5} />
                    }
                  />
                );
              })}
          </div>
        )}
        {isSearchConflicted && filterChips?.length > 0 && (
          <div
            className={`flex flex-col-reverse gap-s ${openFilterChips ? '' : 'pb-l'} ml:flex-col`}
          >
            <div className="flex items-center justify-start gap-xs text-microMedium text-red-medium">
              <InformationCircleIcon className="h-l w-l" />
              <div>Conflicting filters removed from search.</div>
            </div>
            <div className="ease flex flex-wrap gap-s transition-all duration-300">
              <Badge
                key={filterChips[filterChips.length - 1]?.label}
                onClick={() => {
                  const lastChip = filterChips[filterChips.length - 1];
                  handleUpdateFilter(
                    lastChip.filterName,
                    filters[lastChip.filterName] as string
                  );
                }}
                variant={BadgeVariants.Red}
                label={filterChips[filterChips.length - 1]?.label}
                iconPosition="right"
                icon={<XMarkIcon width={20} height={20} strokeWidth={1.5} />}
              />
            </div>
          </div>
        )}
      </section>
      <section className="ml:hidden">
        <div className="mb-l text-h3Regular text-neutral-900">Sort by</div>
        <Accordion
          className="mb-xl"
          buttonClassName="!items-end"
          title={
            <div className="mt-s flex items-center gap-l text-body1Regular text-neutral-800">
              <div>{selectedSortOption}</div>
            </div>
          }
          expanded={expanded}
          setExpanded={() => setExpanded(!expanded)}
          icon={{ id: 'chevron', variant: 'small' }}
        >
          <FormSelect
            className="block min-w-[20%] flex-1 [&>label>button>svg>path]:stroke-blueGrey-0 [&>label>button]:text-bodyBold4 [&>label>button]:text-blueGrey-0 [&>label]:flex [&>label]:items-center [&>label]:space-x-[14px] [&>label]:whitespace-nowrap"
            variant={DropdownMenuItemVariants.SingleSelect}
            disabled={data?.total === 0}
            value={[sort]}
            onChange={([value]) => onSortChange(value)}
            flexDirection={'col'}
            options={SORT_BY_OPTIONS}
          />
        </Accordion>
        <hr className="mt-l border-[1px] border-neutral-200" />
      </section>

      {filterChips?.length > 0 && (
        <hr
          className={`${
            openFilterChips ? 'mt-l' : ''
          } hidden border-[1px] border-neutral-200 ml:block`}
        />
      )}
    </section>
  );
};
