import {
  DropdownMenuGroupType,
  DropdownMenuOptionType,
} from '@/components/molecules/DropdownMenu';
import { DropdownMenuItemVariants } from '@/components/molecules/DropdownMenuItem';
import FormSelect from '@/components/molecules/FormSelect';
import { BODY_TYPES } from '@/lib/constants';
import { FilterType, UpdateFilterActionFields } from '@/types/filters';
import { useMemo } from 'react';
import useWindowSize from '../../../hooks/useWindowSize';

export interface SearchFilterPriceProps {
  minPrice: number | null;
  maxPrice: number | null;
  monthlyPayment: number | null;
}

export interface SearchFilterGeneralProps {
  updateFilter: (body: UpdateFilterActionFields) => void;
  filters: FilterType;
}

interface SearchFilterGeneralInfoSectionProps extends SearchFilterGeneralProps {
  makes: DropdownMenuOptionType[];
  handleUpdateMake: (makes: string[]) => void;
  handleUpdateModel: (models: string[]) => void;
  models: DropdownMenuOptionType[];
}

const defaultYearsOptions = [...Array(6)]
  .map((_, i) => ({
    text: (2020 + i).toString(),
    value: (2020 + i).toString(),
  }))
  .reverse();

const yearsOptions = [{ text: 'All', value: '' }, ...defaultYearsOptions];

export const SearchFilterGeneralInfoSection: React.FC<
  SearchFilterGeneralInfoSectionProps
> = ({
  updateFilter,
  filters,
  makes,
  handleUpdateMake,
  models,
  handleUpdateModel,
}) => {
  const sortAndMap = (items: DropdownMenuOptionType[], category: string) => {
    const allOptions: DropdownMenuGroupType = {
      category,
      index: 1,
      menuItems: items
        .sort((a, b) => a.value.localeCompare(b.value))
        .map(({ text, value }) => ({ text, value })),
    };
    return [allOptions];
  };

  const sortedMakes = useMemo(() => sortAndMap(makes, 'ALL MAKES'), [makes]);

  const sortedModels = useMemo(
    () => sortAndMap(models, 'ALL MODELS'),
    [models]
  );

  const { isMobile } = useWindowSize();

  return (
    <section className="flex flex-col gap-l pt-s">
      <section>
        <FormSelect
          label="Make"
          placeholder="Select Make(s)"
          disabled={makes.length === 0}
          variant={DropdownMenuItemVariants.MultiSelect}
          showCount={false}
          options={sortedMakes}
          value={filters.make}
          autoCompleteInput={true}
          onChange={(newMakes) => handleUpdateMake(newMakes)}
          clearMenu={isMobile ? () => handleUpdateMake([]) : undefined}
        />
      </section>
      <section>
        <FormSelect
          label="Model"
          placeholder="Select Model(s)"
          options={sortedModels}
          disabled={filters.make.length === 0}
          variant={DropdownMenuItemVariants.MultiSelect}
          value={filters.model}
          autoCompleteInput={true}
          showCount={false}
          onChange={(newModels) => handleUpdateModel(newModels)}
          clearMenu={isMobile ? () => handleUpdateModel([]) : undefined}
        />
      </section>
      {process.env.NEXT_PUBLIC_ENABLE_BODY_FEATURE_FILTER === 'true' && (
        <section>
          <FormSelect
            label="Body"
            placeholder="Select Body Type"
            variant={DropdownMenuItemVariants.MultiSelect}
            options={BODY_TYPES.map((type) => ({ text: type, value: type }))}
            value={filters.body}
            showCount={false}
            onChange={(value) => {
              updateFilter({
                fields: {
                  body: value,
                },
              });
            }}
          />
        </section>
      )}
      <section className="flex items-end space-x-[20px]">
        <FormSelect
          className="flex-1"
          dropDownMenuClassName="max-h-[240px]"
          label="Min. Year"
          placeholder="Min. Year"
          variant={DropdownMenuItemVariants.SingleSelect}
          options={
            filters.year_end
              ? yearsOptions.filter(
                  (option) =>
                    Number(option.value) <= Number(filters.year_end) ||
                    option.value === ''
                )
              : yearsOptions
          }
          value={[filters.year_start]}
          onChange={(value) => {
            updateFilter({
              fields: {
                year_start: value[0],
              },
            });
          }}
        />
        <FormSelect
          className="flex-1"
          dropDownMenuClassName="max-h-[240px]"
          label="Max. Year"
          placeholder="Max. Year"
          variant={DropdownMenuItemVariants.SingleSelect}
          options={
            filters.year_start
              ? yearsOptions.filter(
                  (option) =>
                    Number(option.value) >= Number(filters.year_start) ||
                    option.value === ''
                )
              : yearsOptions
          }
          value={[filters.year_end]}
          onChange={(value) => {
            updateFilter({
              fields: {
                year_end: value[0],
              },
            });
          }}
        />
      </section>
    </section>
  );
};
